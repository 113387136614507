import { APIDeliveryValidateResponse } from '@api/types/delivery/validate';
import { CartPageItem } from '@ducks/pages/cart';

export const cartItemChecks = {
    notOnStorage: (item: CartPageItem): boolean => !item.onStorage,
    haveZeroPrice: (item: CartPageItem): boolean => item.price === 0,
    haveBannedDesign: (
        item: CartPageItem,
        bannedDesigns: APIDeliveryValidateResponse['additionalData']
    ): boolean => bannedDesigns.includes(parseInt(item.design, 10)),
};

export const isCartPageItemBlocked = (
    item: CartPageItem,
    bannedDesigns?: APIDeliveryValidateResponse['additionalData']
) =>
    cartItemChecks.haveZeroPrice(item) ||
    cartItemChecks.notOnStorage(item) ||
    (bannedDesigns && cartItemChecks.haveBannedDesign(item, bannedDesigns));
