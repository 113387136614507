import { useSelector } from 'react-redux';
import { userPlatformSelector } from '@ducks/application/user';
import { CSSProperties, useEffect, useState } from 'react';
import { MediaQueryObject } from 'use-media/lib/types';
import useMedia from 'use-media';

const useMobileLayout = (): boolean => {
    const userPlatform = useSelector(userPlatformSelector);
    const [isMobile, setIsMobile] = useState(userPlatform === 'mobile');

    //#region : client-side check using mediaQuery
    const viewPortVariable =
        process.browser &&
        (window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--viewport-sm') ??
            '768px');

    const showMobile =
        viewPortVariable &&
        useMedia({ maxWidth: viewPortVariable } as CSSProperties &
            MediaQueryObject);
    //#endregion

    useEffect(() => {
        if (process.browser) {
            setIsMobile(Boolean(showMobile));
        } else {
            setIsMobile(Boolean(userPlatform === 'mobile'));
        }
    }, [process.browser, showMobile]);

    return isMobile;
};

export default useMobileLayout;
