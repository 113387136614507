import { useEffect, useRef } from 'react';

const useGTMdataLayer = () => {
    const isBrowser = process.browser;

    const dataLayer = useRef<typeof window.dataLayer>([]);

    useEffect(() => {
        if (isBrowser) {
            window.dataLayer = window.dataLayer || [];
            if (dataLayer.current.length > 0) {
                window.dataLayer.push(...dataLayer.current);
            }
            dataLayer.current = window.dataLayer;
        }
    }, [isBrowser]);

    return dataLayer;
};

export default useGTMdataLayer;
