import React, { useEffect } from 'react';
import styles from './styles.scss';
import { useSelector } from 'react-redux';
import { dialogSelector, dialogActions, DialogType } from '@store/ducks/dialog';

import Title from '@uikit/Title';
import useTypedDispatch from '@src/hooks/useTypedDispatch';
import useHideBodyScroll from '@src/hooks/useHideBodyScroll';
import clsx from 'clsx';
import { createPortal } from 'react-dom';

type ModalProps = {
    type: DialogType;
    title?: React.ReactNode;
    fullscreen?: boolean;
    onClose?: () => void;
    className?: string;
    transparent?: boolean;
};

const PortalsRoot = process.browser && document.getElementById('portals');
const Modal: React.FunctionComponent<ModalProps> = (props) => {
    const dialog = useSelector(dialogSelector);
    const dispatch = useTypedDispatch();

    const handleClose = () => {
        props.onClose && props.onClose();
        dispatch(dialogActions.close(props.type));
    };

    useHideBodyScroll(dialog.isOpen[props.type]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && dialog.isOpen[props.type]) {
                handleClose();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    if (!dialog.isOpen[props.type] || !PortalsRoot) {
        return null;
    }

    return createPortal(
        <div
            className={clsx(
                styles.modal,
                props.transparent && styles.transparent
            )}
        >
            <div className={styles.backdrop} onClick={handleClose} />
            <div
                className={clsx(
                    styles.mainContainer,
                    {
                        [styles.fullscreen]: props.fullscreen,
                    },
                    props.className
                )}
            >
                <div className={styles.modalTitle}>
                    <Title>{props.title}</Title>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.828"
                        height="22.828"
                        viewBox="0 0 22.828 22.828"
                        className={styles.close}
                        onClick={handleClose}
                    >
                        <g transform="translate(2696.414 1333.414)">
                            <line
                                className={styles.a}
                                x2="20"
                                y2="20"
                                transform="translate(-2695 -1332)"
                            />
                            <line
                                className={styles.a}
                                y1="20"
                                x2="20"
                                transform="translate(-2695 -1332)"
                            />
                        </g>
                    </svg>
                </div>
                <div className={styles.contentContainer}>{props.children}</div>
            </div>
        </div>,
        PortalsRoot
    );
};

export default Modal;
