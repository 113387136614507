import { useEffect } from 'react';
import * as GlobalStyles from '@src/scss/style.scss';

const useHideBodyScroll = (hide: boolean) => {
    useEffect(() => {
        if (!process.browser) return;
        const bodyClassList = document.body.classList;
        /**
         * TODO: Find way to handle overlap call;
         * Example: When product modal open user can resize to activate mobile layout and hide switch will turn off;
         */
        if (hide) {
            bodyClassList.add(GlobalStyles.hideScroll);
        } else {
            bodyClassList.remove(GlobalStyles.hideScroll);
        }
        return () => {
            bodyClassList.remove(GlobalStyles.hideScroll);
        };
    }, [hide]);
};

export default useHideBodyScroll;
