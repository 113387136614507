import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APICartFullResponse, Data } from '@api/types/cart';
import { RootState, AppThunk } from '@ducks/index';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import {
    cartSelector,
    CartState,
    CART_COOKIE,
    deleteCart,
    cartActions,
} from '@ducks/cart';
import { loadingActions } from '@ducks/application/loading';
import Cookies from 'js-cookie';
import { comparisonItems } from './utils/compareItem';
import { isCartPageItemBlocked } from './utils/cartPageItemChecks';

const { CALL_API } = ApiMiddleware;

export type CartPageItem = APICartFullResponse['data']['items'][0];
export type CartPageResponse = APICartFullResponse['data'];
export type CartPageState = CartPageResponse & {
    isChanged: boolean;
    isLoaded: boolean;
};

const initialState: CartPageState = {
    appliedDiscounts: { promocode: false, wholesale: false },
    discount_sum: 0,
    id: '',
    items: [],
    onStorage: false,
    order_sum: 0,
    promocode: '',
    promocode_description: null,
    isChanged: false,
    isLoaded: false,
};

const cartPage = createSlice({
    initialState,
    name: 'CartPage',
    reducers: {
        updateItem: (state, action: PayloadAction<CartPageItem>) => {
            const itemIndex = state.items.findIndex((item) =>
                comparisonItems(item, action.payload)
            );
            if (itemIndex === -1) return;
            state.items[itemIndex] = action.payload;
        },
        setIsChanged: (state, action: PayloadAction<boolean>) => {
            state.isChanged = action.payload;
        },
        setCartResponse: (state, action: PayloadAction<CartPageResponse>) => ({
            ...state,
            ...action.payload,
        }),
        setIsLoaded: (
            state,
            action: PayloadAction<CartPageState['isLoaded']>
        ) => {
            state.isLoaded = action.payload;
        },
        setInitialValues: () => initialState,
    },
});

export const cartPageActions = cartPage.actions;

export default cartPage.reducer;

export const cartPageSelector = (state: RootState) => state.pages.cart;
export const cartPageHaveBannedItemSelector = createSelector<
    RootState,
    CartPageState,
    CartState,
    boolean
>(cartPageSelector, cartSelector, (CartPageState, CartState) => {
    return CartPageState.items.some((item) =>
        isCartPageItemBlocked(item, CartState.bannedDesign)
    );
});

export const getCartPage: () => AppThunk<Promise<Data | null>> = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const CartState = cartSelector(State);

        const cartId = CartState.id || Cookies.get(CART_COOKIE);

        if (!cartId) throw new Error('cartId is not found');

        dispatch(loadingActions.setLoading(true));
        const { data: CartPageData } = await dispatch<APICartFullResponse>({
            type: CALL_API,
            payload: { url: `rest/cart/${cartId}/full` },
        });
        dispatch(cartPageActions.setCartResponse(CartPageData));
        dispatch(cartPageActions.setIsChanged(false));
        dispatch(loadingActions.setLoading(false));
        dispatch(cartPageActions.setIsLoaded(true));
        dispatch(cartActions.setPromocode(CartPageData.promocode));
        dispatch(cartActions.setPromocodeRejectMessage(''));

        return CartPageData;
    } catch (error) {
        console.error('cant get cart page items', error);
        await dispatch(deleteCart());
        dispatch(cartPageActions.setCartResponse(initialState));
        dispatch(loadingActions.setLoading(false));
        dispatch(cartPageActions.setIsLoaded(true));
    }

    return null;
};

export const updateCartPageItems: (
    item: CartPageItem
) => AppThunk<Promise<void>> = (item) => async (dispatch) => {
    dispatch(cartPageActions.updateItem(item));
    dispatch(cartPageActions.setIsChanged(true));
};
