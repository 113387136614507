import isEqual from 'lodash/isEqual';
import { CartPageItem } from '@ducks/pages/cart';

type CompareItem = Pick<CartPageItem, 'design' | 'product' | 'attributes'>;
export const comparisonItems = (
    checkItem: CompareItem,
    findItem: CompareItem
) => {
    return (
        checkItem.design == findItem.design &&
        isEqual(checkItem.product, findItem.product) &&
        isEqual(checkItem.attributes, findItem.attributes)
    );
};
