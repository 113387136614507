import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export type DialogType =
    | 'item'
    | 'cart'
    | 'order'
    | 'size'
    | 'mobileCategories'
    | 'mobileFilter'
    | 'selectCity'
    | 'image'
    | 'CartDeliveryCountryDetailed';
export interface DialogState {
    isOpen: Record<DialogType, boolean>;
}

const initialState: DialogState = {
    isOpen: {
        cart: false,
        mobileCategories: false,
        mobileFilter: false,
        item: false,
        order: false,
        size: false,
        selectCity: false,
        image: false,
        CartDeliveryCountryDetailed: false,
    },
};

const dialog = createSlice({
    name: 'Dialog',
    initialState,
    reducers: {
        open: (state, action: PayloadAction<DialogType>) => {
            state.isOpen[action.payload] = true;
        },
        close: (state, action: PayloadAction<DialogType>) => {
            state.isOpen[action.payload] = false;
        },
    },
});

export const dialogActions = dialog.actions;
export default dialog.reducer;

export const dialogSelector = (state: RootState) => state.dialog;
